import { baseNumberFormatter, currencyFormatter, dateFormatter, gramFormatter, kgFormatter, liquidFormatter, percentFormatter } from 'components/Grid/formatter'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import messages from './messages'
import titleMessages from 'components/titleMessages'
import { ACCENT_COLORS, BASE_VALUE_FORMAT, CURRENCY_VALUE_FORMAT, CUSTOM_RANGE, DFNS_DATE_FORMAT, FLOATING_RANGE, LIQUID_VALUE_FORMAT, OTHER_GROUP_THRESHOLD, PERCENT_VALUE_FORMAT, SMALL_WEIGHT_VALUE_FORMAT, WEIGHT_VALUE_FORMAT } from 'constants/index'
import { differenceInDays, eachDayOfInterval, endOfDay, format, isBefore, isToday, parseISO, startOfDay } from 'date-fns'
import { isObject, omit, findIndex, forIn, uniqBy, intersection, kebabCase, upperCase, uniq, sum, map, values, find, orderBy, chain, get, keys, reduce, includes, isNil, pickBy, pick, isNaN, cloneDeep, every } from 'lodash'
import { findAndReplaceField, flattenGroupedObject, getNameWithParanthesesByProps, getRangePreset, keyValueObjectToSortedArr, shouldFilterWeekdays, sumKeyValue } from 'utils'
import { calculateComparisonRange, calculateFloatingRange, formatISODate, getDateRangeLimitedToWeekdays, getDateRangeValue, getDateTimeAxisNew, getDateValueFromString, getPreviousDateRange } from 'utils/datetime'
import { v4 as uuidv4 } from 'uuid'
import { PROPERTIES } from './properties'
import { FIELD, FIELD_MAPS, RANGE_GROUP_FIELDS } from './constants'
import { Filter, FilterSettingsMap } from 'hooks/useAvailableFilters'
import { DASHBOARD_ROUTE } from 'routes'
import { FIELD as CP_FIELD, OPTION_VALUES } from 'components/Pickers/ComparisonPeriodPicker'

const COLOR_TYPES = [
  'accentNeutral',
  'accentCornflower',
  'accentGerbera',
  'accentMarigold',
  'accentNarcissus',
  'accentGras',
  'accentSpringstar',
  'accentLilac',
  'accentLavender',
  'accentPeachrose',
  'accentHydrangea',
  'accentRose'
]

export const getRangeGrouping = (range, data) => {
  if (!range) return 'date'
  const diff = differenceInDays(parseISO(range[1]), parseISO(range[0]))
  const dataKeys = data ? Object.keys(data) : null

  const timeKeyRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}/

  if (diff > 100) {
    return 'week'
  } else if (diff === 0 && (dataKeys == null || (dataKeys.length > 0 && (dataKeys ? dataKeys.some(key => timeKeyRegex.test(key)) : false)))) {
    return 'hour'
  }
  return 'date'
}

export const replacePlaceholders = (value, customerSettings) => {
  if (!value.startsWith('$')) { return value }
  const key = value.substring(1)
  switch (key) {
    case 'returnRatioField':
      return customerSettings.returnRatioField
    case 'returnValueRatioField':
      switch (customerSettings.returnRatioField) {
        case 'num_sold':
        default:
          return 'revenue'
        case 'fulfilled_amount':
          return 'fulfilled_value'
      }
    default:
      return value
  }
}

export const mapFieldToDBField = (field, fieldMap) => {
  if (field === '$rangeGroup' || RANGE_GROUP_FIELDS.includes(field)) return field
  const mapped = fieldMap[field]
  if (!mapped) return null
  return mapped
}

export const replaceGroupPlaceholders = (value, allowedGrouping, defaultRangeGroup, rangeGroup) => {
  if (!value.startsWith('$')) { return value }
  const key = value.substring(1)
  switch (key) {
    case 'rangeGroup':
      return allowedGrouping.includes(rangeGroup) ? rangeGroup : defaultRangeGroup
    default:
      return value
  }
}

/**
 * Replaces the field names
 */
const modifyParams = (params, map) => {
  let mParams = [...params]
  forIn(map, (value, key) => {
    mParams = findAndReplaceField(mParams, key, value)
  })

  // illegal relations do have a field: null, so we have to remove them
  return mParams.filter(p => p.field !== null)
}

/**
 * Replaces the placeholder in values of a where filter
 */
const replaceWherePlaceholder = (where, params, mergedFilters) => {
  let originalRange, comparisonRangeParam
  switch (where.value) {
    default:
      return where
    case '$comparisonRange':
      originalRange = find(params, { field: 'date' })
      if (mergedFilters[Filter.COMPARISON]) {
        // we pass the (already on-the-fly updated) filter date range to the method, so we take this instead of getting it from the presets
        const comparingPeriod = calculateComparisonRange(
          mergedFilters.dateRange,
          mergedFilters[Filter.COMPARISON],
          originalRange.value
        )
        comparisonRangeParam = convertDateFilter(comparingPeriod, mergedFilters.weekday)
      }
      if (!comparisonRangeParam || !isValidComparingPeriod(comparisonRangeParam?.value, originalRange.value)) { // TODO: or if no permission
        comparisonRangeParam = getPreviousDateRange(originalRange)
      }
      return { ...where, operator: comparisonRangeParam.operator, value: comparisonRangeParam.value }
    case '$comparisonEndTime':
      // this is basically the same logic like $comparisonRange, but then we only take the end date with the current time.
      const now = new Date()
      originalRange = find(params, { field: 'date' })
      if (mergedFilters[Filter.COMPARISON]) {
        const comparingPeriod = calculateComparisonRange(
          mergedFilters.dateRange,
          mergedFilters[Filter.COMPARISON]
        )
        comparisonRangeParam = convertDateFilter(comparingPeriod, mergedFilters.weekday)
      }
      if (!comparisonRangeParam || !isValidComparingPeriod(comparisonRangeParam?.value, originalRange.value)) { // TODO: or if no permission
        comparisonRangeParam = getPreviousDateRange(originalRange)
      }
      const endDate = parseISO(comparisonRangeParam.value[1])
      endDate.setHours(now.getHours())
      endDate.setMinutes(now.getMinutes())
      endDate.setSeconds(now.getSeconds())
      return { ...where, operator: '<=', value: endDate.toISOString() }
    case '$now':
      return { ...where, value: new Date().toISOString() }
  }
}

export const getPropertyDSRangeGroups = (properties, customerSettings, expectedRangeGroup) => {
  const condition = expectedRangeGroup === 'hour' ? 'groupByHour' : 'default'
  return uniq(properties.map(p => {
    const propertyDef = find(PROPERTIES, { key: replacePlaceholders(p.propKey, customerSettings) })
    const resolvedDataSources = resolveDataSources(propertyDef, customerSettings)
    const dataSources = resolvedDataSources.filter(ds => ds.condition != null ? ds.condition === condition : true)

    return collectDataSourceRangeGroups(p, dataSources, expectedRangeGroup)
  }).flat())
}

export const collectDataSourceRangeGroups = (property, dataSources, expectedRangeGroup) => {
  const rangeGroups = ['week', 'date', 'hour']
  let allGroupings = []
  dataSources.forEach(ds => {
    const fieldMap = FIELD_MAPS[ds.from]
    const allowedGrouping = [
      ...ds.allowedGrouping.map(g => mapFieldToDBField(g, fieldMap)),
      ...ds.allowedGroupingInternal.map(g => mapFieldToDBField(g, fieldMap))
    ].filter(i => i)
    const grouping = []
    if (property.prependGroup) {
      property.prependGroup.forEach(g => {
        const gField = mapFieldToDBField(g, fieldMap)
        if (gField) {
          const rGroup = replaceGroupPlaceholders(gField, allowedGrouping, ds.defaultRangeGroup, expectedRangeGroup)
          if (allowedGrouping.includes(rGroup)) {
            // if necessary, group_by will be modified for the relation, but not if it's the rangeGroup
            grouping.push(ds.modifyParams && g !== '$rangeGroup' ? ds.modifyParams[rGroup] || rGroup : rGroup)
          }
        }
      })
    }
    if (property.appendGroup) {
      property.appendGroup.forEach(g => {
        const gField = mapFieldToDBField(g, fieldMap)
        if (gField) {
          const rGroup = replaceGroupPlaceholders(gField, allowedGrouping, ds.defaultRangeGroup, expectedRangeGroup)
          if (allowedGrouping.includes(rGroup)) {
            // if necessary, group_by will be modified for the relation, but not if it's the rangeGroup
            grouping.push(ds.modifyParams && g !== '$rangeGroup' ? ds.modifyParams[rGroup] || rGroup : rGroup)
          }
        }
      })
    }
    allGroupings = uniq([...allGroupings, ...grouping])
  })
  allGroupings = allGroupings.filter(g => rangeGroups.includes(g))
  return allGroupings
}

export const buildDataQueries = (property, dataSources, filters, mergedFilters, props) => {
  // multiple data sources should not have different grouping and we need a way to prevent this
  // we collect the groupings first, we only care about range related groupings
  let allGroupings = collectDataSourceRangeGroups(property, dataSources, props.expectedRangeGroup)

  // if we have more than two different range groups, we have to fallback to the biggest one.
  // this is because we can't group by multiple range groups
  let forceGroup = null
  if (allGroupings.length > 1) {
    forceGroup = allGroupings.includes('week') ? 'week' : allGroupings.includes('date') ? 'date' : 'hour'
  }

  // on the fly

  const q = dataSources.map(ds => {
    let params = filters
    const fieldMap = FIELD_MAPS[ds.from]
    const allowedGrouping = [
      ...ds.allowedGrouping.map(g => mapFieldToDBField(g, fieldMap)),
      ...ds.allowedGroupingInternal.map(g => mapFieldToDBField(g, fieldMap))
    ].filter(i => i)

    if (property.modifyParams) {
      params = modifyParams(filters, property.modifyParams)
    }
    // merge property.defaultWhere and ds.defaultWhere
    let defaultWhere = [
      ...(property.defaultWhere || []),
      ...(ds.defaultWhere || [])
    ]
    if (defaultWhere && property.omitDefaultWhere) {
      // fields defined in the array omitDefaultWhere will be removed from the defaultWhere
      defaultWhere = defaultWhere.filter(d => !property.omitDefaultWhere.includes(d.field))
    }

    let whereParams = uniqBy([
      ...(defaultWhere || []),
      ...params
    ], 'field')

    // modify where params field names
    if (ds.modifyParams) {
      whereParams = modifyParams(whereParams, ds.modifyParams)
      params = whereParams
    }

    // modify where param field names for the relation
    whereParams = modifyParams(whereParams, fieldMap)
    params = whereParams

    // replacing placeholders in values
    whereParams = whereParams.map(w => replaceWherePlaceholder(w, filters, mergedFilters))

    // if ds.rangeAsDateTime is set, we have to update it's value.
    // But the range field my have changed due to property.modifyParams or ds.modifyParams, so we have to find it first
    if (ds.rangeAsDateTime) {
      let dateField = 'date'
      dateField = (ds.modifyParams && ds.modifyParams.date) || (property.modifyParams && property.modifyParams.date) || 'date'
      const idx = findIndex(whereParams, { field: dateField, operator: 'range' })
      // FIXME: How to deal with weakdays, where we don't have a range but multiple days?
      if (idx !== -1) {
        // replace the value array by parsing the iso date
        const range = whereParams[idx].value
        const newRange = range.map((r, idx) =>
          (idx === 0)
            ? startOfDay(parseISO(r)).toISOString()
            : endOfDay(parseISO(r)).toISOString()
        )
        whereParams[idx].value = newRange
      }
    }

    let grouping = []
    if (property.prependGroup) {
      property.prependGroup.forEach(g => {
        const gField = mapFieldToDBField(g, fieldMap)
        if (gField) {
          const rGroup = replaceGroupPlaceholders(gField, allowedGrouping, ds.defaultRangeGroup, forceGroup || props.rangeGroup)
          if (allowedGrouping.includes(rGroup)) {
            // if necessary, group_by will be modified for the relation, but not if it's the rangeGroup
            grouping.push(ds.modifyParams && g !== '$rangeGroup' ? ds.modifyParams[rGroup] || rGroup : rGroup)
          }
        }
      })
    }
    if (property.appendGroup) {
      property.appendGroup.forEach(g => {
        const gField = mapFieldToDBField(g, fieldMap)
        if (gField) {
          const rGroup = replaceGroupPlaceholders(gField, allowedGrouping, ds.defaultRangeGroup, forceGroup || props.rangeGroup)
          if (allowedGrouping.includes(rGroup)) {
            // if necessary, group_by will be modified for the relation, but not if it's the rangeGroup
            grouping.push(ds.modifyParams && g !== '$rangeGroup' ? ds.modifyParams[rGroup] || rGroup : rGroup)
          }
        }
      })
    }
    const orderBy = property.orderBy
      ? property.orderBy.map(o => {
          const oField = mapFieldToDBField(o, fieldMap)
          if (oField) {
            const rGroup = replaceGroupPlaceholders(oField, allowedGrouping, ds.defaultRangeGroup, forceGroup || props.rangeGroup)
            return ds.modifyParams && o !== '$rangeGroup' ? ds.modifyParams[rGroup] || rGroup : rGroup
          }
          return null
        }).filter(i => i)
      : undefined
    grouping = uniq(grouping)
    if (grouping.length === 1) {
      grouping = grouping[0]
      allGroupings = uniq([...allGroupings, grouping])
    } else {
      allGroupings = uniq([...allGroupings, ...grouping])
    }

    return {
      from: ds.from,
      select: Array.isArray(ds.aggregate) && Array.isArray(ds.select)
        ? ds.aggregate.map((agg, i) => `${agg}(${ds.select[i]})`)
        : [`${property.aggregate || ds.aggregate}(${ds.select})`],
      ...(grouping.length > 0 ? { group_by: grouping } : undefined),
      ...(orderBy ? { order_by: orderBy } : undefined),
      where: whereParams,
      identifier: [uuidv4()] // this will not be sent to the server, but is needed to identify the query
    }
  })
  return q
}

/**
 * utility function that checks the data and throws errors if needed
 * @param {*} array the needed keys
 * @param {*} data the passed data
 */
export const validateData = (neededKeys, data, fetching, errors) => {
  if (!data) {
    // check if there are errors
    const errorSet = errors.filter(i => i)
    if (errorSet.length > 0) {
      return errorSet
    }
  } else {
    if (!Array.isArray(data)) return 'Invalid Data'
    const dataKeys = data.map(d => d.key)
    if (intersection(dataKeys, neededKeys).length !== neededKeys.length) {
      return `Invalid Data Keys. Needed: ${neededKeys.join(', ')} Got: ${dataKeys.join(', ')}`
    }
  }
  return true
}

export const getColor = (colorKey) => {
  if (!COLOR_TYPES.includes(colorKey)) throw new Error(`Invalid color key: ${colorKey}`)
  const [namespace, color] = kebabCase(colorKey).split('-')
  if (namespace !== 'accent') throw new Error(`Invalid color namespace: ${namespace}`)
  return ACCENT_COLORS[upperCase(color)]
}

export const getFormatterPropsForReturnType = (returnType) => {
  switch (returnType) {
    case 'number':
    default:
      return BASE_VALUE_FORMAT
    case 'currency':
      return CURRENCY_VALUE_FORMAT
    case 'percent':
      return PERCENT_VALUE_FORMAT
    case 'weight':
      return WEIGHT_VALUE_FORMAT
    case 'liquid':
      return LIQUID_VALUE_FORMAT
    case 'gram':
      return SMALL_WEIGHT_VALUE_FORMAT
  }
}

export const getFormatterForReturnType = (returnType) => {
  switch (returnType) {
    case 'number':
    default:
      return baseNumberFormatter
    case 'percent':
      return percentFormatter
    case 'currency':
      return currencyFormatter
    case 'weight':
      return kgFormatter
    case 'liquid':
      return liquidFormatter
    case 'gram':
      return gramFormatter
  }
}

export const calcPieChartData = (data = {}, otherString, ungroupedString, breakdownGroup, customerSettings) => {
  const flattenData = flattenGroupedObject(data, breakdownGroup, customerSettings)
  const total = sum(map(values(flattenData), Math.abs))
  const mapNameValue = ([name, value]) => ({ name: name === 'null' ? ungroupedString : name, value })
  const others = []
  let lastOther = null

  return orderBy(
    chain(flattenData)
      .defaultTo({})
      .toPairs()
      .reduce((acc, [name, value]) => {
        if (Math.abs(value) / total < OTHER_GROUP_THRESHOLD) {
          others.push(name)
          lastOther = [name, value]
          return { ...acc, [otherString]: (get(acc, [otherString], 0) + value) }
        }
        return { ...acc, [name]: value }
      }, {})
      .toPairs()
      .map(([name, value]) =>
        name === otherString
          ? (others.length === 1
              ? mapNameValue(lastOther)
              : {
                  name,
                  value,
                  group: others
                })
          : mapNameValue([name, value])
      )
      .value(),
    ['value'],
    ['desc']
  )
}

// const flatifyData = (data, grouping, dataKeys, currentLevel = 0, baseObject = {}) => {
//   let flatData = []

//   if (currentLevel < grouping.length) {
//     forEach(data, (value, key) => {
//       const newBaseObject = { ...baseObject }
//       newBaseObject[grouping[currentLevel]] = key
//       flatData = flatData.concat(flatifyData(value, grouping, dataKeys, currentLevel + 1, newBaseObject))
//     })
//   } else {
//     const finalObject = { ...baseObject }
//     for (let i = 0; i < dataKeys.length; i++) {
//       finalObject[dataKeys[i]] = data
//     }
//     flatData.push(finalObject)
//   }

//   return flatData
// }

// const mergeDataSets = (dataSets, groupingFields) => {
//   const mergedData = {}
//   const dataFields = []

//   dataSets.forEach(dataSet => {
//     dataSet.forEach(item => {
//       // Create a unique key based on grouping fields
//       const key = groupingFields.map(field => item[field]).join('|')

//       if (!mergedData[key]) {
//         mergedData[key] = { ...item }
//       } else {
//         Object.keys(item).forEach(field => {
//           if (!groupingFields.includes(field)) {
//             if (!dataFields.includes(field)) {
//               dataFields.push(field)
//             }
//             mergedData[key][field] = item[field]
//           }
//         })
//       }
//     })
//   })

//   const values = Object.values(mergedData)

//   // at the end iterate through all items. if one of the data fields is missing as key, set it to null.
//   const finalValues = values.map(item => {
//     dataFields.forEach(field => {
//       if (item[field] == undefined) {
//         item[field] = null
//       }
//     })
//     return item
//   })
//   return finalValues
// }

export const calcTableChartData = (data = [], customerSettings, groupBy, mergedFilters) => {
  // data is an array of objects, each object contains a data prop which is an object with key-value-pairs
  // first we collect all keys from all objects in the data array
  const clonedDataFlattened = cloneDeep(data).map(d => ({ ...d, data: flattenGroupedObject(d.data, groupBy, customerSettings) }))
  // FIXME: This is a WIP approach to make data flattening and merging better. It's needed for deep grouping, e.g. pivoting.
  // const test = clonedDataFlattened.map(k => flatifyData(k.data, ['location', 'item_category_1', 'item'], [k.key === 'prev' ? `${k.propKey}_compare` : k.propKey]))
  // const merged = mergeDataSets(test, ['location', 'item_category_1', 'item'])

  let keys, compDateMap
  if (groupBy === '$rangeGroup') {
    const dataRange = find(clonedDataFlattened, { key: 'data' })?.queries[0].where.find(w => w.field.includes('date')).value
    const prevRange = find(clonedDataFlattened, { key: 'prev' })?.queries[0].where.find(w => w.field.includes('date')).value
    if (dataRange && prevRange) {
      const dataRangeDays = eachDayOfInterval({ start: parseISO(dataRange[0]), end: parseISO(dataRange[1]) }).map(d => formatISODate(d))
      const prevRangeDays = eachDayOfInterval({ start: parseISO(prevRange[0]), end: parseISO(prevRange[1]) }).map(d => formatISODate(d))
      // build the compDataMap for each date
      compDateMap = {}
      dataRangeDays.forEach((date, idx) => {
        compDateMap[date] = prevRangeDays[idx]
      })

      if (dataRangeDays.length === 1) {
        // if we have a single day, we also have to prepare the mapping for hourly data
        for (let i = 1; i <= 24; i++) {
          const dataHour = `${dataRangeDays[0]}T${String(i).padStart(2, '0')}`
          const prevHour = `${prevRangeDays[0]}T${String(i).padStart(2, '0')}`
          compDateMap[dataHour] = prevHour
        }
      }
    }

    // when we group by $rangeGroup, we have different keys. We don't want the prev data leading to unique rows
    keys = uniq(clonedDataFlattened.reduce((acc, item) => {
      return [...acc, ...(item.data && item.key === 'data' && typeof item.data === 'object' ? Object.keys(item.data) : [])]
    }, []))
  } else {
    keys = uniq(clonedDataFlattened.reduce((acc, item) => {
      return [...acc, ...(item.data && typeof item.data === 'object' ? Object.keys(item.data) : [])]
    }, []))
  }

  // we build the table data for AG Grid
  const tableData = keys.map(key => {
    let title = null
    const row = { id: key, title: key }
    clonedDataFlattened.forEach(item => {
      // when we group by $rangeGroup, we have different keys. For each row, we have to calculate the comparison date.
      if (groupBy === '$rangeGroup' && item.key === 'data' && mergedFilters[Filter.COMPARISON] != null && mergedFilters[Filter.COMPARISON].option !== 'target') {
        const comparisonDate = compDateMap ? compDateMap[key] : null
        if (comparisonDate) {
          row.compKey = comparisonDate
        }
      }

      if (!item.data) {
        row[item.propKey] = null
      } else {
        const value = item.data[key]
        // if value is an object itself, the key is the remote_pk of the relation
        // we have only one key, which is the remote_pk and a value
        if (value && typeof value === 'object') {
          row.remote_pk = Object.keys(value)[0]
          row[item.propKey] = value[row.remote_pk]
          if (!title) {
            switch (groupBy) {
              case FIELD.LOCATION:
                title = getNameWithParanthesesByProps(key, row.remote_pk, customerSettings)
                break
              case FIELD.ITEM:
                title = getNameWithParanthesesByProps(key, row.remote_pk, { showLocationRemotePK: true })
                break
            }
            row.id = item.key === 'prev' ? `${title}_compare` : title
            row.title = item.key === 'prev' ? `${title}_compare` : title
          }
        } else {
          // this is a special case when we have different keys for the same row.
          // We can use the previously calculated compKey to attach the comparison value to the current row.
          if (groupBy === '$rangeGroup' && item.key === 'prev' && row.compKey) {
            row[`${item.propKey}_compare`] = item.data[row.compKey]
          } else {
            row[item.key === 'prev' ? `${item.propKey}_compare` : item.propKey] = item.data[key]
          }
        }
      }
    })
    return row
  })
  return tableData
}

export const calcBarChartData = (data = {}, groupBy, range, hasExactDasyInRange, otherString, ungroupedString, breakdownGroup, customerSettings) => {
  // this was the old way, calculating the total value over all bars. See https://linear.app/delicious-data/issue/TEC-1219/dashboard-metric#comment-b6dbcbc5
  // const totalValues = calcTotalValues(data)
  // const totalValue = sum(map(values(totalValues), Math.abs))
  let xAxis = getDateTimeAxisNew(groupBy, range, hasExactDasyInRange)
  const dataKeys = Object.keys(data)

  if (includes(dataKeys, 'null') && dataKeys.length > 1) {
    xAxis = {
      ...xAxis,
      [ungroupedString]: 0
    }
  }

  const overallSums = {}
  const result = map(keys(xAxis), date => {
    const items = (dataKeys.length === 1 && dataKeys[0] === 'null') ? data.null : data[date]
    const flattenItems = flattenGroupedObject(items, breakdownGroup, customerSettings)
    const totalValue = sum(map(values(flattenItems), Math.abs))
    let inOthers = 0
    let lastOther = null
    const bars = reduce(
      keys(flattenItems),
      (acc, name) => {
        if (
          Math.abs(flattenItems[name]) / totalValue <
            OTHER_GROUP_THRESHOLD
        ) {
          lastOther = name
          inOthers += 1
          return { ...acc, [otherString]: (acc[otherString] || 0) + flattenItems[name] }
        }
        const transpiledName = name === 'null' ? ungroupedString : name
        return { ...acc, [transpiledName]: flattenItems[name] }
      },
      {}
    )
    if (inOthers === 1) {
      const value = bars[otherString]
      delete bars[otherString]
      const transpiledName = lastOther === 'null' ? ungroupedString : lastOther
      bars[transpiledName] = value
    }
    for (const name in bars) {
      const value = bars[name]
      sumKeyValue(overallSums, name, value)
    }
    const agg = {
      date,
      ...bars
    }
    return agg
  })

  return {
    data: result,
    bars: keyValueObjectToSortedArr(overallSums).map((i) => i.value !== null ? i.key : null).filter(i => i)
  }
}

export const calcLineChartData = (data = [], rangeGroup, range, hasExactDasyInRange, ungroupedString, otherString, nullsToZeros) => {
  const xAxisPoints = getDateTimeAxisNew(rangeGroup, range, hasExactDasyInRange)
  const axisKeys = Object.keys(xAxisPoints)

  const calcFromGroups = data.map(d => calcBarChartData(d.data, rangeGroup, range, otherString, ungroupedString))
  const returnTypeDict = {}
  // for each property we have to get the returnType
  data.forEach((property, idx) => {
    const propertyItem = find(PROPERTIES, { key: property.propKey })
    const returnType = getPropertyReturnType(property, propertyItem)
    const bars = calcFromGroups[idx].bars
    bars.forEach(bar => {
      returnTypeDict[bar] = returnType
    })
  })

  const chartData = axisKeys.map((dateAxis) => {
    const date = getDateValueFromString(dateAxis)

    const obj = { date }
    data.forEach((property) => {
      let dataKey = dateAxis
      // if property.data has only one key and that key is `null`, then the grouping by that rangeGroup was not possible.
      if (Object.keys(property.data).length === 1 && Object.keys(property.data)[0] === 'null') {
        dataKey = 'null'
      }

      if (Array.isArray(property.data)) {
        // we have a calculated prop with multiple results.
        // we merge them together in an array
        obj[property.propKey] = property.data.map(v => v[dataKey])
      } else if (isObject(property.data[dataKey])) {
        // ignore, this will be added by calcBarChartData
      } else {
        obj[property.propKey] = property.data[dataKey]
      }

      if (nullsToZeros && obj[property.propKey] == null) {
        obj[property.propKey] = 0
      }
    })
    return obj
  })

  // chartData is an array for each dateAxis, test is an array where data prop is an array for each date axis
  // let's merge them together into chartData
  calcFromGroups.forEach((p) => {
    if (p.bars.length === 0) return
    p.data.forEach((obj) => {
      const date = parseISO(obj.date).valueOf()
      const existing = findIndex(chartData, { date })
      if (existing !== -1) {
        chartData[existing] = { ...chartData[existing], ...omit(obj, ['date']) }
      }
    })
  })
  const groupLines = uniq(calcFromGroups.map(p => p.bars).flat())

  const colors = {}
  data.forEach((property) => {
    colors[property.propKey] = property.color
  })
  return {
    data: chartData,
    colors,
    groupLines,
    returnTypeDict
  }
}

export const calcForecastAccuracyData = (data = [], rangeGroup, range, hasExactDasyInRange, intl) => {
  const xAxisPoints = getDateTimeAxisNew(rangeGroup, range, hasExactDasyInRange)
  const axisKeys = Object.keys(xAxisPoints)

  const plannedSoldDiffTitle = intl.formatMessage(titleMessages.plannedSoldDiff)
  const forecastSoldDiffTitle = intl.formatMessage(titleMessages.forecastSoldDiff)

  const calculated = axisKeys.map((date) => {
    const numSold = data[0][date] || null
    const numPlanned = data[1][date] || null
    const numForecast = data[2][date] || null
    // const name = format(new Date(date), groupBy === 'week' ? DFNS_WEEK_FORMAT : DFNS_DATE_FORMAT)

    const isPlannedSoldNil = !isNil(numPlanned) && !isNil(numSold)
    const isForecastSoldNil = !isNil(numForecast) && !isNil(numSold)
    const plannedSoldDiff = isPlannedSoldNil ? numPlanned - numSold : null
    const forecastSoldDiff = isForecastSoldNil ? numForecast - numSold : null
    // FIXME: Do we want to support relative values? Should come by the definition then
    return {
      date,
      [plannedSoldDiffTitle]: plannedSoldDiff,
      [forecastSoldDiffTitle]: forecastSoldDiff
      // plannedSoldRel: isPlannedSoldNil
      //   ? (plannedSoldDiff / numSold) * 100
      //   : null,
      // forecastSoldRel: isForecastSoldNil
      //   ? (forecastSoldDiff / numSold) * 100
      //   : null
    }
  })

  return {
    data: calculated,
    bars: [
      plannedSoldDiffTitle,
      forecastSoldDiffTitle
    ],
    colors: [
      'LAVENDER|50',
      'SPRINGSTAR|50'
    ]
  }
}

export const calcListChartData = (currentData, prevData, definition, propertyItem, formatter, customerSettings, intl) => {
  let chartData = []
  let sumValues = 0

  // firt, we have to flatten the nested data
  const flatData = flattenGroupedObject(currentData, definition.data.groupBy, customerSettings)
  const flatPrevData = prevData ? flattenGroupedObject(prevData, definition.data.groupBy, customerSettings) : null

  for (const [label, value] of Object.entries(flatData)) {
    const obj = { label, value }

    if (definition.data.groupBy === '$rangeGroup') {
      // reformat the label using the dateFormatter
      obj.label = dateFormatter({ value: label, colDef: { intl } })
    }

    if (value != null && typeof (value) === 'object') {
      // nested data

      const entries = Object.entries(value)
      /* entries is an array:
      [
          [
            "786901",
            12
          ],
          [
            "786902",
            12
          ]
        ]
      */
      // we take the first entry and use it in the label. The value should be the sum of all entry values
      const innerLabel = entries[0][0]
      const innerValue = entries.reduce((acc, val) => acc + val[1], 0)
      obj.label = getNameWithParanthesesByProps(label, innerLabel, customerSettings)
      obj.value = innerValue
      obj.prevValue = flatPrevData
        ? flatPrevData[label] ? flatPrevData[label][innerLabel] ? flatPrevData[label][innerLabel] : null : null
        : null
    } else {
      obj.prevValue = flatPrevData
        ? flatPrevData[label] ? flatPrevData[label] : null
        : null
    }
    if (obj.value !== null && obj.value !== 'null') {
      obj.difference = obj.value - obj.prevValue
      obj.inverted = propertyItem.invertedDeltaArrow === true
      obj.formattedValue = formatter({ value: obj.value, minimize: intl.formatMessage(globalMessages.millionSymbol) })
      obj.realDelta = propertyItem.returnType === 'percent' ? obj.difference : Math.abs(obj.difference / obj.prevValue) * Math.sign(obj.difference)
      obj.formattedPrevValue = formatter({ value: obj.prevValue })
      obj.formattedDifference = formatter({ value: obj.difference })
      sumValues += obj.value
      chartData.push(obj)
    }
  }
  if (sumValues !== 0 && chartData.length > 0) {
    const sort = definition.data.sort || 'desc'
    chartData = orderBy(chartData, ['value', 'label'], [sort, 'asc'])
    const highestValue = sort === 'desc' ? chartData[0].value : chartData[chartData.length - 1].value

    // now for each item we calculate its percentage of the total and of the highest value
    chartData.forEach(item => {
      item.percentageOfTotal = (item.value / sumValues) * 100
      item.percentageOfHighest = (item.value / highestValue) * 100
    })
    return chartData
  }
  return null
}

export const getGroupLabel = (intl, group, singular = false) => {
  switch (group) {
    default:
      return group
    case FIELD.LOCATION:
      return singular
        ? intl.formatMessage(labelMessages.location)
        : intl.formatMessage(labelMessages.locations)
    case FIELD.ITEM_CATEGORY_1:
      return intl.formatMessage(labelMessages.itemGroup1)
    case FIELD.ITEM_CATEGORY_2:
      return intl.formatMessage(labelMessages.itemGroup2)
    case FIELD.ITEM:
      return singular
        ? intl.formatMessage(labelMessages.item)
        : intl.formatMessage(labelMessages.items)
    case FIELD.WASTE_CATEGORY:
      return intl.formatMessage(labelMessages.wasteCategory)
    case FIELD.OFFERING_GROUP_1:
      return intl.formatMessage(labelMessages.offeringGroup1)
    case FIELD.OFFERING_GROUP_2:
      return intl.formatMessage(labelMessages.offeringGroup2)
    case FIELD.NONE:
      return intl.formatMessage(globalMessages.nullOption)
    case '$rangeGroup':
      return intl.formatMessage(labelMessages.pointInTime)
    case FIELD.ITEM_TAG:
      return intl.formatMessage(labelMessages.itemTag)
    case FIELD.LOCATION_TAG:
      return intl.formatMessage(labelMessages.locationTag)
  }
}

export const hasPropertyPermission = (property, { customerType, foodWasteMode }, permissions, customerSettings) => {
  if (typeof property === 'string') property = find(PROPERTIES, { key: property })
  else if (typeof property === 'object' && property.propKey) property = find(PROPERTIES, { key: property.propKey })
  if (!property) return false
  const allowedSet = []
  if (property.customerTypes) {
    allowedSet.push(property.customerTypes.includes(customerType))
  }
  if (property.permissions) {
    property.permissions.forEach((p) => {
      allowedSet.push(permissions[p] === true)
    })
  }
  if (property.customerSettings) { // Note: currently not used
    const keys = Object.keys(property.customerSettings)
    // check if each value of each key matches the customerSettings with loadash
    const matches = keys.map(key => property.customerSettings[key] === customerSettings[key])
    allowedSet.push(every(matches))
  }
  if (property.foodWasteModes) {
    allowedSet.push(property.foodWasteModes.includes(foodWasteMode))
  }
  return allowedSet.every(i => i)
}

export const hasReportPermission = (definition, customerType, permissions, customerSettings) => {
  if (!definition) return false
  const allowedSet = []
  if (definition.customerTypes) {
    allowedSet.push(definition.customerTypes.includes(customerType))
  }
  if (definition.permissions) {
    definition.permissions.forEach((p) => {
      allowedSet.push(permissions[p] === true)
    })
  }
  definition.data.properties.forEach((p) => {
    allowedSet.push(hasPropertyPermission(p, customerType, permissions, customerSettings))
  })
  // return true if every item in allowedSet is true
  return allowedSet.every(i => i)
}

/**
 * Returns the label of a property based on defined labelKeys anywhere
 * @param {*} definitionProperty The property from the definition
 * @param {*} property The referenced property item
 * @param {*} intl intl object
 * @returns The label for the property
 */
export const getPropertyName = (definitionProperty, property, intl) => {
  let intlKey
  if (!definitionProperty) {
    return ''
  }
  if (definitionProperty.labelKey) {
    intlKey = definitionProperty.labelKey
  } else if (property && property.labelKey) {
    intlKey = property.labelKey
  }
  return messages[intlKey]
    ? intl.formatMessage(messages[intlKey])
    : labelMessages[intlKey]
      ? intl.formatMessage(labelMessages[intlKey])
      : intlKey
}

/**
 * Returns the color of a property based on defined colors anywhere
 * @param {*} property The referenced property item
 * @param {*} shade The shade. If undefined, property.strokeShade will be used
 * @returns The color
 */
export const getPropertyColor = (property, shade) => {
  let color
  if (property && property.color) {
    color = property.color
  }
  const c = getColor(color)
  return c[shade || (property ? property.strokeShade : 50)]
}

/**
 * Returns the returnType of a property based on defined returnTypes anywhere
 * @param {*} definitionProperty The property from the definition
 * @param {*} property The referenced property item
 * @returns The returnType
 */
export const getPropertyReturnType = (definitionProperty, property) => {
  if (definitionProperty.returnType) {
    return definitionProperty.returnType
  } else if (property && property.returnType) {
    return property.returnType
  }
  return 'number'
}

export const calcListBarCounts = (height, data) => {
  if (height == null || data == null) {
    return null
  }
  const tabsHeight = 3.5
  const cardRowHeightInRem = 2.5
  const footerHeightInRem = 1.5

  const pxPerRem = parseInt(window.getComputedStyle(document.documentElement).fontSize)

  const innerHeight = height - (tabsHeight * pxPerRem) - (footerHeightInRem * pxPerRem)
  const result = {
    barsCount: data ? Object.keys(data).length + 1 : 1,
    maxBarsCount: Math.ceil(innerHeight / (cardRowHeightInRem * pxPerRem))
  }
  return result
}

const performArithmetic = (arithmeticObject, arrValues) => {
  // operands is an array and each item can be a number, a string with the index for the values array or an arithmetic object itself
  // the operator can be +, -, * or / and will be applied to the operands

  /** Sample Arithmetic Object:
  {
    operator: '/',
    operands: [
      {
        operator: '-',
        operands: ['0', '1'] // this will be replaced by the values from arrValues
      },
      {
        operator: '*',
        operands: [
          '0',
          4 // this will be replaced by the value itself
        ]
      }
    ]
  }
  **/
  const { operands, operator } = arithmeticObject
  // first we need to replace the operands with their values
  const replacedOperands = operands.map(o => {
    if (typeof o === 'number') {
      return o
    } else if (typeof o === 'string') {
      const index = parseInt(o)
      return arrValues[index]
    } else if (typeof o === 'object') {
      return performArithmetic(o, arrValues)
    }
    throw new Error('Invalid operand type')
  })

  // now we can perform the actual operation
  const result = replacedOperands.reduce((acc, val) => {
    switch (operator) {
      case '+':
        return acc + val
      case '-':
        return acc - val
      case '*':
        return acc * val
      case '/':
        return acc / val
      default:
        throw new Error(`Unsupported arithmetic operation: ${operator}`)
    }
  })
  return Math.abs(result) === Infinity ? null : result
}

/**
 * This methods performs arithmetic operations on multiple data sources by calulating the different data
 * @param {*} arithmetic the arithmetic array of the property
 * @param {*} data An array of results for the dataSources
 * @returns An object with the same keys, but the value is calculated from both data sources
 */
export const performPropertyArithmetic = (arithmetic, data) => {
  let dataResultLevels

  // if data is an array consisting of simple numbers, we can directly perform the arithmetic operation
  if (data.every(d => typeof d === 'number')) {
    return performArithmetic(arithmetic, data)
  }

  // each item in data is a key-value object.
  // we will remove null values from both objects first and make sure, both have the same keys
  const cleanedData = data.map(d => pickBy(d, (value) => {
    // value can be a value itself or another object with one key and it's value
    // we want to remove all null values
    if (value != null && typeof value === 'object') {
      const innerValue = Object.values(value)[0]
      dataResultLevels = 1
      return innerValue != null && innerValue !== 'null'
    }
    dataResultLevels = 0
    return value != null && value !== 'null'
  }))
  const keys = cleanedData.map(d => Object.keys(d))
  const commonKeys = intersection(...keys)

  // now for each common key we can do the arithmetics operation
  const resultSet = {}
  commonKeys.forEach(key => {
    // get the set of values to calculate for this entry. this can be first or second level
    const valueSet = cleanedData.map(d => {
      const value = d[key]
      if (typeof value === 'object') {
        return Object.values(value)[0]
      }
      return value
    })

    // now we can perform the arithmetic operation
    resultSet[key] = performArithmetic(arithmetic, valueSet)
  })

  // if we are having dataResultLevels === 1, we need to make sure the value is wrapped like it was in the original data
  if (dataResultLevels === 1) {
    commonKeys.forEach(key => {
      const innerValue = resultSet[key]
      const outerValue = cleanedData[0][key]
      resultSet[key] = { [Object.keys(outerValue)[0]]: innerValue }
    })
  }
  return resultSet
}

const determineReportTitleKey = (definition) => {
  if (!definition || !definition.data) return undefined
  if (definition.data.groupBy) {
    return 'metricByGroup'
  }
  return 'metric'
}

export const getReportTitle = (definition, intl, returnEmpty) => {
  if (definition.title) return definition.title
  let titleKey = determineReportTitleKey(definition)

  if (!titleKey) return returnEmpty ? '' : intl.formatMessage(labelMessages.untitled)

  const metrics = definition.data.properties.map(p => find(PROPERTIES, { key: p.propKey }))
  if (metrics.length === 0) {
    return returnEmpty ? '' : intl.formatMessage(labelMessages.untitled)
  }
  const group = definition.data.groupBy

  // if we group by item or location and we have multiple metrics, we use itemComparison or locationComparison
  // if (metrics.length > 1 && (group === FIELD.ITEM || group === FIELD.LOCATION)) {
  //   return intl.formatMessage(messages[`${group}Comparison`])
  // }

  if (metrics.length > 3) {
    titleKey = titleKey === 'metricByGroup' ? 'nMetricsByGroup' : 'nMetrics'
  }

  const groupLabel = group ? getGroupLabel(intl, group, true) : ''
  const metricLabels = metrics.map(metric => getPropertyName(metric, metric, intl)).join(', ')
  const m = intl.formatMessage(messages[titleKey], { group: groupLabel, metric: metricLabels, count: metrics.length })

  // in English, everything is lower case, only the first character is upper case
  if (intl.locale === 'en-US') {
    return m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
  }
  return m
}

export const getReportDescription = (definition, intl) => {
  // TODO
  if (definition.description) return definition.description
  return 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil reprehenderit corporis aliquam consectetur quam aut?'
}

// sometimes we fetch hourly data, but backend returns only daily data.
// we have to detect this
export const determineRealGroup = (range, data) => {
  const diff = differenceInDays(parseISO(range[1]), parseISO(range[0]))
  const dataKeys = data ? Object.keys(data) : null

  const timeKeyRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}/

  if (diff > 100) {
    return 'week'
  } else if (diff === 0 && (dataKeys == null || (dataKeys.length > 0 && (dataKeys ? dataKeys.some(key => timeKeyRegex.test(key)) : false)))) {
    return 'hour'
  }
  return 'date'
}

/**
 * Collect all keys to filter out from a deep array of objects
 */
export const getKeysToFilterOut = (resultsArr, propertyDef) => {
  const keysToFilterOut = {}

  const collectKeys = (obj, keyTree = []) => {
    for (const key in obj) {
      const value = obj[key]
      if (typeof value === 'object' && value !== null) {
        collectKeys(value, [...keyTree, key])
      } else {
        const k = keyTree[keyTree.length - 1]
        switch (propertyDef.filterOut) {
          case 'null':
            if (value == null) {
              if (!keysToFilterOut[k]) keysToFilterOut[k] = []
              keysToFilterOut[k].push(k)
            }
            break
          case 'nullAndZero':
            if (value == null || value === 0) {
              if (!keysToFilterOut[k]) keysToFilterOut[k] = []
              keysToFilterOut[k].push(k)
            }
            break
          default:
            break
        }
      }
    }
  }

  resultsArr.forEach((result) => {
    collectKeys(result)
  })

  return keysToFilterOut
}

export const getDefaultSizesByReportType = (type) => {
  switch (type) {
    case 'summarized-area-with-comparison':
    case 'text':
      return {
        w: 3,
        h: 5
      }
    case 'list':
    case 'table':
      return {
        w: 4,
        h: 8
      }
    default:
      return {
        w: 4,
        h: 10
      }
  }
}

export const getMinSizesByReportType = (type) => {
  switch (type) {
    case 'summarized-area-with-comparison':
      return {
        minW: 3,
        minH: 5
      }
    case 'text':
      return {
        minW: 1,
        minH: 2
      }
    case 'table':
    case 'list':
      return {
        minW: 4,
        minH: 8
      }
    default:
    case 'pie':
    case 'bar':
    case 'line':
      return {
        minW: 4,
        minH: 10
      }
  }
}

const validFilterSettingKeys = ['date', 'dateRange', 'location', 'wasteCategory', 'og2', 'og2Names', 'og1', 'og1Names', 'itemName', 'itemCategory1', 'itemCategory2']

const getFieldName = (key, v2) => {
  switch (key) {
    default:
      return key
    case 'dateRange':
      return 'date'
    case 'location':
      return v2 ? FIELD.LOCATION_ID : 'sales_location'
    case 'wasteCategory':
      return v2 ? FIELD.WASTE_CATEGORY_ID : 'category'
    case 'og2':
      return 'component_obj'
    case 'og2Names':
      return v2 ? FIELD.OFFERING_GROUP_2 : 'component'
    case 'og1':
      return 'menuline_item'
    case 'og1Names':
      return v2 ? FIELD.OFFERING_GROUP_1 : 'menuline'
    case 'itemName':
      return v2 ? FIELD.ITEM_ID : 'item__name'
    case 'itemCategory1':
      return v2 ? FIELD.ITEM_CATEGORY_1 : 'item__category_1'
    case 'itemCategory2':
      return v2 ? FIELD.ITEM_CATEGORY_2 : 'item__category_2'
  }
}

/**
 * Checks that the comparing period is valid
 * @param {*} comparingPeriod The comparing period value array
 * @param {*} rangeValue The date range value array
 */
export const isValidComparingPeriod = (comparingPeriod, rangeValue) => {
  if (!comparingPeriod || !comparingPeriod.length) return false
  if (comparingPeriod.some(d => rangeValue.includes(d))) return false
  return isBefore(parseISO(comparingPeriod[comparingPeriod.length - 1]), parseISO(rangeValue[0]))
}

const convertDateFilter = (rangeValue, weekdays) => {
  rangeValue = rangeValue.map(v => format(v, DFNS_DATE_FORMAT))
  if (shouldFilterWeekdays(weekdays)) {
    const singleDates = rangeValue.length === 0 ? [] : getDateRangeLimitedToWeekdays(rangeValue, weekdays)
    return {
      field: 'date',
      operator: 'in',
      value: singleDates
    }
  } else {
    return {
      field: 'date',
      operator: 'range',
      value: rangeValue
    }
  }
}

/**
 * Converts given filters into a data-query where filter array
 */
export const convertFilters = (settings, v2, filterOmitted = {}, sources) => {
  // we need to convert the settings according to the Dashboard filter settings map.
  const keyMap = FilterSettingsMap[DASHBOARD_ROUTE]
  // replace the key names with the correct ones
  const mappedSettings = {}
  for (const key in settings) {
    if (keyMap[key]) {
      mappedSettings[keyMap[key]] = settings[key]
    }
    mappedSettings[key] = settings[key]
  }

  const filters = pick(mappedSettings, validFilterSettingKeys)
  const passedFilterKeys = Object.keys(filters)

  let dateFilter = null
  const rangeValue = getDateRangeValue(filters.dateRange, sources)
  dateFilter = convertDateFilter(rangeValue, v2 ? settings.weekday : settings.weekdays)

  const filterSet = [
    ...(dateFilter ? [dateFilter] : [])
  ]

  passedFilterKeys.forEach((key) => {
    // if key is dateRange, we already handled it
    if (key === 'dateRange') return
    // if key is date and we already have a date filter, we don't add it again
    if (key === 'date' && dateFilter) return
    // if the filter is omitted, we don't add it to the filterSet
    if (filterOmitted[key]) return
    // if not v2 and the filter is an empty array, we don't add it to the filterSet
    if (!v2 && Array.isArray(filters[key]) && filters[key].length === 0) return

    const operator = Array.isArray(filters[key]) ? 'in' : '='
    filterSet.push({
      field: getFieldName(key, v2),
      operator,
      value: Array.isArray(filters[key]) ? filters[key].map(v => v === 'null' ? null : v) : filters[key]
    })
  })

  return {
    filters: filterSet
  }
}

/**
 * Extract the filters from the param and convert it into a data-query where filter array
 * This method is only used in Dashboard v1!
 */
export const extractFilters = (settings, allLocations, allOg1, allOg2, allItemCategories1, allItemCategories2, allItemNames, wasteCategories) => {
  // we need to detect if we ran in the case where we had selected all entities and therefore didn't pass the filter at all
  const arrLocation = settings.location ? Array.isArray(settings.location) ? settings.location : [settings.location] : null
  const filterOmitted = {
    location: (allLocations && arrLocation && arrLocation.length === allLocations.length) === true,
    og1: (allOg1 && settings.og1 && settings.og1.length === allOg1.length) === true,
    og2: (allOg2 && settings.og2 && settings.og2.length === allOg2.length) === true,
    og1Names: (allOg1 && settings.og1Names && settings.og1Names.length === allOg1.length) === true,
    og2Names: (allOg2 && settings.og2Names && settings.og2Names.length === allOg2.length) === true,
    itemCategory1: (allItemCategories1 && settings.itemCategory1 && settings.itemCategory1.length === allItemCategories1.length) === true,
    itemCategory2: (allItemCategories2 && settings.itemCategory2 && settings.itemCategory2.length === allItemCategories2.length) === true,
    itemName: (allItemNames && settings.itemName && settings.itemName.length === allItemNames.length) === true,
    wasteCategory: (wasteCategories && settings.wasteCategory && settings.wasteCategory.length === wasteCategories.length) === true
  }

  const p = convertFilters(settings, false, filterOmitted).filters
  return {
    filters: p,
    filterOmitted
  }
}

export const isDefinedFilterValue = (i) => {
  if (i == null) return false
  // empty array
  if (Array.isArray(i) && i.length === 0) return false

  // empty object
  if (!Array.isArray(i) && typeof i === 'object' && (Object.keys(i).length === 0 || i.value?.length === 0)) return false

  // unset comparison option
  if (i.option === 'unset') return false

  return true
}

/**
 * Merges global filters and local filters together and returns a converted query params array
 * @param {*} globalFilters The global dashboard filters coming from the context
 * @param {*} localFilters The local report filters coming from the report definition
 * @returns An array of where filters
 */
export const mergeFilters = (globalFilters, localFilters = {}, itemsFromTags) => {
  const cleanedUpGlobalFilters = pickBy(globalFilters, isDefinedFilterValue)
  const cleanedUpLocalFilters = pickBy(localFilters, isDefinedFilterValue)
  const allKeys = uniq([...Object.keys(cleanedUpGlobalFilters), ...Object.keys(cleanedUpLocalFilters)])

  // we merge the filters together, but local filters will win
  const mergedFilters = {}
  allKeys.forEach(field => {
    const gF = cleanedUpGlobalFilters[field]
    const lF = cleanedUpLocalFilters[field]

    if (gF && !lF) {
      mergedFilters[field] = gF
    } else if (lF) {
      mergedFilters[field] = lF
    }
  })

  // if we have items resolved by item tags, we will build an intersection
  if (itemsFromTags) {
    if (mergedFilters.item) {
      mergedFilters.item = intersection(mergedFilters.item, itemsFromTags.map(i => i.toString()))
    } else {
      mergedFilters.item = itemsFromTags
    }
    delete mergedFilters.itemTag
  }

  return mergedFilters
}

/**
 * Returns the resolved data sources of a property definition. Takes care of referenced data sources and placeholders.
 * @param {*} property The property definition
 * @param {*} customerSettings The customer settings
 * @returns Resolved data sources
 */
export const resolveDataSources = (property, customerSettings, fetchTargetValue) => {
  const resolveDataSource = (dataSource) => {
    if (dataSource.propKey) {
      const referencedProperty = find(PROPERTIES, { key: replacePlaceholders(dataSource.propKey, customerSettings) })
      return resolveDataSources(referencedProperty, customerSettings, fetchTargetValue)
    } else {
      return dataSource
    }
  }

  if (fetchTargetValue && property.goalIntervalValue) {
    return [{
      from: 'GoalInterval',
      select: property.goalIntervalValue,
      aggregate: property.returnType === 'percent' ? 'avg' : 'sum',
      allowedGrouping: [FIELD.LOCATION, FIELD.LOCATION_PK],
      allowedGroupingInternal: ['date'],
      defaultRangeGroup: 'date',
      condition: 'default'
    }]
  }
  return property.dataSources.map(resolveDataSource).flat()
}

/**
 * Returns the resolved data sources of a report definition. Takes care of referenced data sources and placeholders.
 * @param {*} definition The report definition
 * @param {*} customerSettings The customer settings
 * @returns Resolved data sources
 */
export const resolveAllDataSources = (definition, customerSettings) => {
  return definition.data.properties.map(p => {
    const property = find(PROPERTIES, { key: p.propKey })
    return resolveDataSources(property, customerSettings)
  }).flat()
}

export const getEffectiveRange = (mergedFilters, properties) => {
  if (mergedFilters[Filter.DATE_RANGE].option === FLOATING_RANGE) {
    return calculateFloatingRange(mergedFilters[Filter.DATE_RANGE].value, true)
  } else if (mergedFilters[Filter.DATE_RANGE].option !== CUSTOM_RANGE) {
    return getRangePreset(mergedFilters[Filter.DATE_RANGE].option, true, properties)
  }

  return mergedFilters[Filter.DATE_RANGE].value
}

/**
 * Removes all null and NaN values from the object or array. Array items or object values can be nested objects
 * @param {*} toFilter Array or object to filter
 */
export const filterOutNullAndNaNDeep = (toFilter) => {
  if (Array.isArray(toFilter)) {
    const resArr = toFilter.map(i => {
      if (i != null && typeof i === 'object') return filterOutNullAndNaNDeep(i)
      return i
    }).filter(i => i != null && !isNaN(i))
    if (resArr.length === 0) return undefined
    return resArr
  }
  const resObj = Object.fromEntries(Object.entries(toFilter).map(([k, v]) => {
    if (v != null && typeof v === 'object') return [k, filterOutNullAndNaNDeep(v)]
    return [k, v]
  }).filter(([_, v]) => v != null && !isNaN(v)))
  if (Object.keys(resObj).length === 0) return undefined
  return resObj
}

export const getRangeFilter = (filters) => {
  const validFields = ['date', 'due_by', 'due_by__date']
  const invalidOperators = ['lte', 'gte', 'lt', 'gt']
  return find(filters, (f) => validFields.includes(f.field) && !invalidOperators.includes(f.operator))
}

// get the middle value of two values
export const getMiddle = (valueArr) => {
  const sorted = valueArr.sort((a, b) => a - b)
  return sorted[0] + (sorted[1] - sorted[0]) / 2
}

export const hasComparisonPeriodFilter = (filters) => {
  if (!filters[Filter.COMPARISON]) return false
  if (filters[Filter.COMPARISON][CP_FIELD.comparisonOption] !== OPTION_VALUES.UNSET &&
    filters[Filter.COMPARISON][CP_FIELD.comparisonOption] !== OPTION_VALUES.TGT
  ) return true
  return false
}

export const hasComparisonTargetFilter = (filters) => {
  if (!filters[Filter.COMPARISON]) return false
  if (filters[Filter.COMPARISON][CP_FIELD.comparisonOption] === OPTION_VALUES.TGT) return true
  return false
}

const ALLOWED_TARGET_COMPARISON_FITLER_FIELDS = [Filter.DATE_RANGE, Filter.COMPARISON, Filter.WEEKDAY, Filter.LOCATION]
export const doesSupportTargetComparison = (property, mergedFilters) => {
  if (!property?.goalIntervalValue) return false
  const filterKeys = Object.keys(mergedFilters)

  // if we have filter keys which are not included in the allowed filter set, return false
  if (filterKeys.some(f => !ALLOWED_TARGET_COMPARISON_FITLER_FIELDS.includes(f))) return false
  return true
}

export const extendProperty = (property) => {
  const propertyDef = find(PROPERTIES, { key: property.propKey })
  if (!propertyDef) {
    return property
  }
  return {
    ...property,
    ...pick(propertyDef, ['appendGroup'])
  }
}

export const extendPropertyForChartType = (definition, property, mergedFilters, customerSettings) => {
  const withComparisonPeriod = hasComparisonPeriodFilter(mergedFilters)
  const withTargetComparison = hasComparisonTargetFilter(mergedFilters)
  const propertyDef = find(PROPERTIES, { key: replacePlaceholders(property.propKey, customerSettings) })
  const supportsTargetComparison = doesSupportTargetComparison(propertyDef, mergedFilters)

  switch (definition.type) {
    case 'table':
      switch (definition.data.groupBy) {
        case FIELD.LOCATION:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' }
                    ]
                  }
                ]
              : []),
            ...(withTargetComparison && supportsTargetComparison
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK],
                    goalData: true
                  }
                ]
              : [])
          ]
        case FIELD.ITEM:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy, FIELD.ITEM_PK]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy, FIELD.ITEM_PK],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' }
                    ]
                  }
                ]
              : [])
          ]
        case FIELD.ITEM_TAG:
        case FIELD.LOCATION_TAG:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy],
              defaultWhere: [
                ...property.defaultWhere || [],
                { field: definition.data.groupBy, operator: '!=', value: null }
              ]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' },
                      { field: definition.data.groupBy, operator: '!=', value: null }
                    ]
                  }
                ]
              : [])
          ]
        default:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' }
                    ]
                  }
                ]
              : [])
          ]
      }
    case 'list':
      switch (definition.data.groupBy) {
        case FIELD.LOCATION:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' }
                    ]
                  }
                ]
              : []),
            ...(withTargetComparison && supportsTargetComparison
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK],
                    goalData: true
                  }
                ]
              : [])
          ]
        case FIELD.ITEM:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy, FIELD.ITEM_PK]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy, FIELD.ITEM_PK],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' }
                    ]
                  }
                ]
              : [])
          ]
        case FIELD.ITEM_TAG:
        case FIELD.LOCATION_TAG:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy],
              defaultWhere: [
                ...property.defaultWhere || [],
                { field: definition.data.groupBy, operator: '!=', value: null }
              ]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' },
                      { field: definition.data.groupBy, operator: '!=', value: null }
                    ]
                  }
                ]
              : [])
          ]
        default:
          return [
            {
              ...property,
              key: 'data',
              appendGroup: [definition.data.groupBy]
            },
            ...(withComparisonPeriod
              ? [
                  {
                    ...property,
                    key: 'prev',
                    appendGroup: [definition.data.groupBy],
                    defaultWhere: [
                      { field: 'date', operator: 'range', value: '$comparisonRange' }
                    ]
                  }
                ]
              : [])
          ]
      }
    case 'pie':
      switch (definition.data.groupBy) {
        case FIELD.LOCATION:
          return {
            ...property,
            key: 'data',
            appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK]
          }
        case FIELD.ITEM:
          return {
            ...property,
            key: 'data',
            appendGroup: [definition.data.groupBy, FIELD.ITEM_PK]
          }
        default:
          return {
            ...property,
            key: 'data',
            appendGroup: [definition.data.groupBy]
          }
      }
    case 'bar':
      if (definition.data.groupBy) {
        switch (definition.data.groupBy) {
          case FIELD.LOCATION:
            return {
              ...property,
              key: 'data',
              prependGroup: ['$rangeGroup'],
              appendGroup: [definition.data.groupBy, FIELD.LOCATION_PK],
              orderBy: ['$rangeGroup', definition.data.groupBy, FIELD.LOCATION_PK]
            }
          case FIELD.ITEM:
            return {
              ...property,
              key: 'data',
              prependGroup: ['$rangeGroup'],
              appendGroup: [definition.data.groupBy, FIELD.ITEM_PK],
              orderBy: ['$rangeGroup', definition.data.groupBy, FIELD.ITEM_PK]
            }
          default:
            return {
              ...property,
              key: 'data',
              prependGroup: ['$rangeGroup'],
              appendGroup: [definition.data.groupBy],
              orderBy: ['$rangeGroup', definition.data.groupBy]
            }
        }
      }
      return {
        ...property,
        key: 'data',
        prependGroup: ['$rangeGroup']
      }
    case 'summarized-area-with-comparison':
      return [
        {
          ...property,
          key: 'area-data',
          prependGroup: ['$rangeGroup']
        },
        ...(withComparisonPeriod
          ? [
              {
                ...property,
                key: 'sum-prev-range',
                enforceSum: true, // in case we get objects, we enforce the summarization
                defaultWhere: [
                  { field: 'date', operator: 'range', value: '$comparisonRange' }
                ],
                // this is needed for gramPerSale special case
                ...(property.appendGroup ? { appendGroup: ['$rangeGroup', ...property.appendGroup] } : undefined)
              }
            ]
          : []),
        ...(withTargetComparison && supportsTargetComparison
          ? [
              {
                ...property,
                key: 'sum-prev-range',
                goalData: true
              }
            ]
          : []),
        {
          ...property,
          key: 'sum-current-range',
          enforceSum: true, // in case we get objects, we enforce the summarization
          // this is needed for gramPerSale special case
          ...(property.appendGroup ? { appendGroup: ['$rangeGroup', ...property.appendGroup] } : undefined)
        }
      ]
    case 'line':
      if (definition.data.groupBy) {
        return {
          ...property,
          key: 'data',
          prependGroup: ['$rangeGroup'],
          appendGroup: [definition.data.groupBy]
        }
      }
      return {
        ...property,
        key: 'data',
        prependGroup: ['$rangeGroup']
      }
    default:
      return property
  }
}

export const shouldFetchPartialData = (dateRangeFilter, key) => {
  return (key.includes('prev') && isToday(parseISO(dateRangeFilter.value[1])))
}
